import { useAuth0 } from '@auth0/auth0-react';
import { UserInteraction } from '@naf/user-interaction';
import React from 'react';
import { useHistory } from 'react-router';
import { AvailabilityInfo } from '../../../../../types/EventType';
import useSelector from '../../../redux/typedHooks';

type Props = {
  availabilityInfo: AvailabilityInfo;
  organizer: { title: string; type: string };
  className?: string;
};

const AvailabilityInfoBox = ({ availabilityInfo, organizer, className }: Props) => {
  const history = useHistory();
  const { loginWithRedirect } = useAuth0();
  const clubName = useSelector((state) => state.myMembership.customerInformation.data.membership?.localClub?.clubName);

  if (availabilityInfo === AvailabilityInfo.UserNotLoggedIn) {
    return (
      <UserInteraction
        variant="info"
        title={`Kun for medlemmer av ${organizer.title}`}
        buttonLink={{
          text: 'Logg inn',
          onClick: () => {
            loginWithRedirect({ appState: { returnTo: history.location.pathname } });
            return false; // wrong type in UserInteraction?
          },
        }}
        className={className}
      >
        Dette arrangementet er kun for medlemmer av en spesefikk lokalavdeling. For å se hvilken lokalavdeling du hører
        til, må du logge inn.
      </UserInteraction>
    );
  }
  if (
    availabilityInfo === AvailabilityInfo.UserNotInLocalDepartment ||
    availabilityInfo === AvailabilityInfo.UserNotInMcClub
  ) {
    return (
      <UserInteraction
        variant="info"
        className={className}
      >{`Dette arrangementet er kun for medlemmer av ${organizer.title}. Du er medlem av ${clubName}`}</UserInteraction>
    );
  }
  return null;
};

export default AvailabilityInfoBox;
