import { ButtonLink } from '@naf/button-link';
import { Text } from '@naf/text';
import { breakpoints, spacing } from '@naf/theme';
import styled from 'styled-components';

export const MapButton = styled(ButtonLink)`
  grid-area: mapbutton;
  margin-left: ${spacing.space8};
`;

export const ButtonWrapper = styled.div`
  display: flex;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template: auto auto / 1fr 5fr;
  grid-template-areas: 'hitcount hitcount' 'filter hitlist';
  grid-gap: ${spacing.space32};
  padding: ${spacing.space32} 0;

  @media (max-width: ${breakpoints.s}) {
    grid-template: auto auto auto / 1fr;
    grid-template-areas: 'filter' 'hitcount' 'hitlist';
    grid-template-columns: 1fr;
  }
`;

const CountText = styled.div`
  grid-area: hitcount;
  align-self: center;
`;

const Filter = styled.div`
  grid-area: filter;
  display: flex;
  flex-direction: column;

  > * {
    margin-bottom: ${spacing.space8};
  }
`;

const FilterHeader = styled(Text)`
  margin: 0 0 ${spacing.space8} 0;
`;

const ResultHeader = styled(FilterHeader)`
  text-transform: uppercase;
  margin: 0;
`;

const BorderWrapper = styled.div`
  border-bottom: 1px solid #e6e6e6;
  padding: ${spacing.space24} 0;
`;

const HitList = styled.div`
  grid-area: hitlist;
  display: flex;
  flex-direction: column;
  padding: 0 ${spacing.space8};
  grid-gap: ${spacing.space56};
`;

const ShowMoreButtonWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;

  p {
    margin-left: 20px;
  }
`;

const IconHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${spacing.space16};

  & > svg,
  & > img {
    margin-right: ${spacing.space8};
  }
`;
const TextNoMargin = styled(Text)`
  margin: 0;
`;

const Time = styled.div`
  margin-bottom: ${spacing.space16};

  & > svg,
  & > img {
    margin-right: ${spacing.space8};
  }
`;

const Location = styled.div`
  & > svg,
  & > img {
    margin-right: ${spacing.space8};
  }
`;

const TextIconMargin = styled(Text)`
  margin: 0 0 0 28px;
`;

export default {
  MapButton,
  ButtonWrapper,
  CountText,
  Filter,
  FilterHeader,
  ResultHeader,
  BorderWrapper,
  HitList,
  Wrapper,
  ShowMoreButtonWrapper,
  IconHeader,
  TextNoMargin,
  Location,
  TextIconMargin,
  Time,
};
