import React, { ReactNode, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { Text, TextVariant } from '@naf/text';
import { useDispatch } from 'react-redux';
import { Input, Message } from '@naf/input';
import { breakpoints, spacing } from '@naf/theme';
import { Button } from '@naf/button';
import { nafColor } from '@nafcore/theme';
import { ButtonLink } from '@naf/button-link';
import { Link, useHistory } from 'react-router-dom';
import { Spinner } from '../../../components/spinner/Spinner';
import { actions } from '../../../redux/modules/nafEvents';
import useSelector from '../../../redux/typedHooks';
import EventList from '../components/EventList';
import S from '../Styles';
import { getURLParams } from '../../../lib/getUrlParams';
import { InternalSearchCardType } from '../../../components/layout/InternalSearchMetaData';
import { LayoutWithMainContent } from '../../../components/layout/Layout';
import { NafLocationsAppContext } from '../NafLocationsApp';
import { useWindowLocation } from '../../../hooks/useWindowLocation';
import LocationChips from '../components/LocationChips';

const Events = () => {
  const data = useContext(NafLocationsAppContext);
  const { seoConfig } = data || {};
  const url = useWindowLocation();

  const dispatch = useDispatch();
  const history = useHistory();
  const { postalCode } = getURLParams() as { postalCode?: string };
  const [postalNumberInputState, setPostalNumber] = React.useState<string | undefined>(postalCode || undefined);
  const [take, setTake] = React.useState<number>(6);
  useEffect(() => {
    // fetch search results on mount and when postalCode changes in Url
    dispatch(actions.getNafEvents.request({ take, postalNumber: postalCode || undefined }));
  }, [take, dispatch, postalCode]);

  const { monthlyEvents, errorState, fetchState, total, validation } = useSelector((state) => state.nafEvents);
  const totalReturned = monthlyEvents.reduce((acc, curr) => acc + curr.events.length, 0);

  const searchWithPostalNumber = () => {
    // Update url with postalcode on return and button press
    history.push(`${history.location.pathname}?postalCode=${postalNumberInputState}`);
  };

  let content: ReactNode | null;

  if (fetchState === 'initial' || (fetchState === 'isUpdating' && monthlyEvents.length === 0)) {
    content = <Spinner />;
  } else if (fetchState === 'error' && !validation) {
    content = <Message error>{errorState?.message}</Message>;
  } else {
    content = (
      <Wrapper>
        <StyledH4 variant={TextVariant.ArticleTextLargeHeader}>Se hva NAF gjør der du bor</StyledH4>
        <SearchWrapper>
          <Label>
            Ditt postnummer
            <StyledInput
              maxLength={4}
              id="zip"
              label="Ditt postnummer"
              type="number"
              value={postalNumberInputState}
              onChange={setPostalNumber}
              width="auto"
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter') searchWithPostalNumber();
              }}
            />
          </Label>
          <SearchButton variant="secondary" color={nafColor.primary.moss} onClick={searchWithPostalNumber}>
            {fetchState === 'isUpdating' ? <Spinner /> : 'Se arrangementer'}
          </SearchButton>
        </SearchWrapper>
        {validation && <Message error>{validation.message}</Message>}
        {postalCode && fetchState === 'success' && <Message success>{`Viser arrangementer i ${postalCode}`}</Message>}
        {!validation && (
          <EventList
            monthlyEvents={monthlyEvents}
            noHitText={
              <>
                Ser ikke ut som det er noen arrangementer i ditt område fremover. Har du fortsatt lyst til å engasjere
                deg? Ta kontakt med din lokalavdeling og hør hvordan du kan bidra!
              </>
            }
            button={<ButtonLink as={Link} to="lokalavdeling" text="Kontakt din lokalavdeling" />}
          />
        )}
        {!validation && typeof total !== 'undefined' && total > 0 && (
          <S.ShowMoreButtonWrapper>
            {totalReturned < total && (
              <Button variant="outline" onClick={() => setTake(Math.min(take + 10, total))}>
                {fetchState === 'isUpdating' ? <Spinner /> : 'Vis flere'}
              </Button>
            )}
            <p>{`Viser ${totalReturned} av ${total} arrangementer`}</p>
          </S.ShowMoreButtonWrapper>
        )}
      </Wrapper>
    );
  }

  return (
    <LayoutWithMainContent
      title="Arrangementer"
      description="Liste over NAF sine arrangementer"
      seoDescription="Liste over NAF sine arrangementer"
      isHiddenFromSearch={seoConfig?.isHiddenFromSearch}
      seoTitle="Arrangementer"
      internalSearchMetaData={{ cardType: InternalSearchCardType.Simple }}
      url={url}
    >
      <LocationChips />
      {content}
    </LayoutWithMainContent>
  );
};

const Wrapper = styled.div`
  margin-top: ${spacing.space48};

  @media (max-width: ${breakpoints.s}) {
    margin-top: 0;
  }
`;

const StyledH4 = styled(Text)`
  margin-bottom: ${spacing.space16};
`;

const StyledInput = styled(Input)`
  width: 151px;
  height: 48px;

  @media (max-width: ${breakpoints.s}) {
    width: 120px;
  }
`;

const SearchButton = styled(Button)`
  height: ${spacing.space48};
  padding: 0 ${spacing.space24};
  margin-left: ${spacing.space16};
  white-space: nowrap;
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

const Label = styled.label`
  font-size: 0.875rem;
  font-weight: 300;
`;

export default Events;
