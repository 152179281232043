import React from 'react';
import { Text, TextVariant } from '@naf/text';
import { breakpoints, spacing } from '@naf/theme';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { Chips } from '@naf/chips';
import { useHandleNafLocationsRoutes } from '../useHandleNafLocationsRoutes';

export type Location = 'lokalavdelinger' | 'mc-klubb' | 'naf-senter' | 'ovingsbaner' | 'arrangementer';

const LocationChips = () => {
  const history = useHistory();
  const { locations, name: nafLocationName } = useHandleNafLocationsRoutes();
  const currentLocation = Object.values(locations).find((location) =>
    history.location.pathname.includes(location.fullUrl),
  );
  if (!currentLocation || history.location.pathname.split('/').length > 3) return null;
  const selectedChip = currentLocation.slug;
  return (
    <>
      <Text variant={TextVariant.Header1}>{nafLocationName}</Text>
      <ChipsWrapper>
        {Object.values(locations).map(({ name, slug, fullUrl }) => (
          <Chips
            text={name}
            key={slug}
            onClick={() => history.push(fullUrl)}
            variant={slug === selectedChip ? 'signature' : 'outline'}
            icon={slug === selectedChip && 'checked'}
          />
        ))}
      </ChipsWrapper>
    </>
  );
};

const ChipsWrapper = styled.div`
  display: flex;
  @media (max-width: ${breakpoints.m}) {
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow-x: auto;
    scrollbar-width: none; // for Firefox
    ::-webkit-scrollbar {
      display: none; // for Chrome, Safari and Opera
    }
    > button {
      display: flex;
      flex: none;
    }
    margin-left: -${spacing.space24};
    margin-right: -${spacing.space24};
    padding-left: ${spacing.space24};
    padding-right: ${spacing.space24};
    :before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: ${spacing.space24};
      height: 250px;
      background: linear-gradient(90deg, #ffffff 2.84%, rgba(255, 255, 255, 0) 100%);
      z-index: 10;
    }
    :after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      height: 250px;
      width: ${spacing.space24};
      background: linear-gradient(270deg, #ffffff 2.84%, rgba(255, 255, 255, 0) 100%);
    }
  }
`;

export default LocationChips;
