import React, { createContext } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import loadable from '@loadable/component';
import { SiteStructureType } from '../../../../types/siteStructureType';
import { NotFound } from '../errors/NotFound';
import EventPage from './pages/EventPage';
import Events from './pages/Events';
import LocalArticle from './pages/LocalArticle';
import LocalBenefitArticle from './pages/LocalBenefitArticle';
import LocalDepartment from './pages/LocalDepartment';
import LocalDepartments from './pages/LocalDepartments';
import MCClubs from './pages/MCClubs';
import NafCenters from './pages/NafCenters';
import PracticeTracks from './pages/PracticeTracks';
import { NafLoactionSlugs, useHandleNafLocationsRoutes } from './useHandleNafLocationsRoutes';

const PracticeTrack = loadable(() => import('./pages/PracticeTrack'));

export const NafLocationsAppContext = createContext<SiteStructureType>(null);

const NafLocationsApp = ({ data }: { data?: SiteStructureType }) => {
  const { locations } = useHandleNafLocationsRoutes();

  if (!data) return <NotFound />;
  const nafLocationPath = data.slug;

  return (
    <NafLocationsAppContext.Provider value={data}>
      <Switch>
        {/* redirect from 'her-finner-du-naf' til 'her-finner-du-naf/lokalavdelinger' */}
        <Redirect exact from={`/${nafLocationPath}`} to={locations[NafLoactionSlugs.localDepartment].fullUrl} />

        {/* local department routes */}
        <Route exact path={locations[NafLoactionSlugs.localDepartment].fullUrl} component={LocalDepartments} />
        <Route
          exact
          path={`${locations[NafLoactionSlugs.localDepartment].fullUrl}/:slug`}
          component={LocalDepartment}
        />
        <Route
          exact
          path={`${locations[NafLoactionSlugs.localDepartment].fullUrl}/:lokalSlug/fordeler/:slug`}
          component={LocalBenefitArticle}
        />
        <Route
          exact
          path={`${locations[NafLoactionSlugs.localDepartment].fullUrl}/:lokalSlug/artikkel/:slug`}
          component={LocalArticle}
        />

        {/* Mc club routes */}
        <Route exact path={locations[NafLoactionSlugs.mcClubs].fullUrl} component={MCClubs} />
        <Route exact path={`${locations[NafLoactionSlugs.mcClubs].fullUrl}/:slug`} component={LocalDepartment} />
        <Route
          exact
          path={`${locations[NafLoactionSlugs.mcClubs].fullUrl}/:lokalSlug/fordeler/:slug`}
          component={LocalBenefitArticle}
        />
        <Route
          exact
          path={`${locations[NafLoactionSlugs.mcClubs].fullUrl}/:lokalSlug/artikkel/:slug`}
          component={LocalArticle}
        />

        {/* NAF center routes */}
        <Route exact path={locations[NafLoactionSlugs.nafCenter].fullUrl} component={NafCenters} />

        {/* Practice track routes */}
        <Route exact path={locations[NafLoactionSlugs.practiceTracks].fullUrl} component={PracticeTracks} />
        <Route exact path={`${locations[NafLoactionSlugs.practiceTracks].fullUrl}/:slug`} component={PracticeTrack} />

        {/* Event routes */}
        <Route exact path={locations[NafLoactionSlugs.events].fullUrl} component={Events} />
        <Route exact path={`${locations[NafLoactionSlugs.events].fullUrl}/:slug`} component={EventPage} />
      </Switch>
    </NafLocationsAppContext.Provider>
  );
};

export default NafLocationsApp;
